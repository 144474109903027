<template>
  <div id="users">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 用户列表卡片区 -->
    <el-card class="box-card">
      <el-form :inline="true" ref="form" :model="queryMap" label-width="70px">
        <el-form-item label="职位">
          <el-input
            @keyup.enter.native="searchCheck"
            @clear="searchCheck"
            clearable
            v-model="queryMap.title"
            placeholder="请输入职位查询"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-left:50px;">
          <el-button  @click="reset" icon="el-icon-refresh">重置</el-button>
          <el-button type="primary" @click="searchCheck" icon="el-icon-search">查询</el-button>
          <el-button type="success" icon="el-icon-plus" @click="openAdd" v-hasPermission="'check:add'">添加</el-button>
        </el-form-item>
      </el-form>

      <!-- 表格区域 -->
      <el-table
              v-loading="loading"
              :data="checkList"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              stripe
              border
              style="width: 100%;" :height="tableHeight"
              ref="myTable">
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column prop="title" label="职位" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="orgName" label="所属机构" width="140" show-overflow-tooltip></el-table-column>
        <el-table-column prop="degree" label="学历要求" width="140" show-overflow-tooltip></el-table-column>
        <el-table-column prop="area" label="工作地区" width="140" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180" sortable show-overflow-tooltip>
          <template slot-scope="scope" >
            <span>{{formatDateTime(scope.row.createTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button   v-hasPermission="'check:edit'" size="mini" type="primary" icon="el-icon-edit-outline" @click="edit(scope.row.id)"></el-button>
            <el-button v-hasPermission="'check:delete'" type="danger" size="mini" icon="el-icon-delete" @click="del(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="margin-top:10px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryMap.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryMap.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>

      <!-- 添加对话框 -->
      <el-dialog title="添加招聘信息" @close="closeDialog" :visible.sync="addDialogVisible" width="60%;">
        <!-- 表单 -->
        <span>
          <el-form
            :model="addForm"
            :label-position="labelPosition"
            :rules="addFormRules"
            ref="addFormRef"
            label-width="80px"
          >
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="职位" prop="title">
                    <el-input v-model="addForm.title"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="所属机构" prop="orgName">
                    <el-input v-model="addForm.orgName"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="学历要求" prop="degree">
                    <el-input v-model="addForm.degree"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="工作地区" prop="area">
                    <el-input v-model="addForm.area"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <quill-editor
                        ref="myQuillEditor"
                        v-model="addForm.content"
                        :options="editorOption"
                        class="ql-editor"
                />
              </el-col>
            </el-row>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAdd">取 消</el-button>
          <el-button type="primary" @click="addCheck" :loading="btnLoading" :disabled="btnDisabled">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改对话框 -->
      <el-dialog title="修改招聘信息" :visible.sync="editDialogVisible" width="60%" @close="editClose">
        <span>
          <el-form
            :model="editForm"
            :label-position="labelPosition"
            :rules="addFormRules"
            ref="editFormRef"
            label-width="80px"
          >
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="职位" prop="title">
                    <el-input v-model="editForm.title"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="所属机构" prop="orgName">
                    <el-input v-model="editForm.orgName"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="学位要求" prop="degree">
                    <el-input v-model="editForm.degree"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="工作地区" prop="area">
                    <el-input v-model="editForm.area"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <quill-editor
                        ref="myQuillEditor"
                        :options="editorOption"
                        v-model="editForm.content"
                        class="ql-editor"
                />
              </el-col>
            </el-row>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelUpdate">取 消</el-button>
          <el-button type="primary" @click="updateCheck" :loading="btnLoading" :disabled="btnDisabled">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import {quillEditor, Quill} from 'vue-quill-editor'
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/ImageResize', ImageResize)
let fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = ['10px', '12px', '14px', '16px', '20px', '24px', '36px', false] //false表示默认值
Quill.register(fontSizeStyle, true)
export default {
  data() {
    return {
      btnLoading: false,
      btnDisabled: false,
      loading: true,
      total: 0,
      addDialogVisible: false, //添加对话框,
      editDialogVisible: false, //修改对话框
      labelPosition: "right", //lable对齐方式
      tableHeight:null,
      uploadEditor: this.getSysEditorUpload(),
      editorOption: {
        placeholder:"请输入内容......",
        modules: {
          ImageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize']
          },
          ImageExtend: {
            loading: true,  // 可选参数 是否显示上传进度和提示语
            name: 'img',  // 图片参数名
            size: 1,  // 可选参数 图片大小，单位为M，1M = 1024kb
            action: this.getSysEditorUpload(),  // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return this.getSysFileOrigin() + res.files[0].fingerprint
            },
            headers: (xhr) => {},  // 可选参数 设置请求头部
            start: () => {},  // 可选参数 自定义开始上传触发事件
            end: () => {},  // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {},  // 可选参数 自定义网络错误触发的事件
            change: (xhr, formData) => {} // 可选参数 选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // container为工具栏，此次引入了全部工具栏，也可自行配置
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{'header': 1}, {'header': 2}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{'script': 'sub'}, {'script': 'super'}],
              [{'indent': '-1'}, {'indent': '+1'}],
              [{'direction': 'rtl'}],
              [{'size': fontSizeStyle.whitelist}],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              [{'color': []}, {'background': []}],
              [{'font': [] }],
              [{'align': []}],
              ['clean'],
              ['link', 'image', 'video']
            ],
            handlers: {
              'image': function () {  // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      },
      //查询对象
      queryMap: {
        pageNum: 1,
        pageSize: 10,
        title: ""
      },
      checkList: [],
      addForm: {
        title: "",
        orgName:"",
        degree:"",
        area:"",
        content:""
      }, //添加表单
      editForm: {}, //更新表单
      addFormRules: {
        title: [
          { required: true, message: "请输入职位", trigger: ['blur','change'] },
          { min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: ['blur','change'] }
        ],
        orgName: [
          { required: true, message: "请输入所属机构", trigger: ['blur','change'] },
          { min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: ['blur','change'] }
        ],
        degree: [
          { required: true, message: "请输入学位要求", trigger: ['blur','change'] },
          { min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: ['blur','change'] }
        ],
        area: [
          { required: true, message: "请输入工作地区", trigger: ['blur','change'] },
          { min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: ['blur','change'] }
        ]
      }, //添加表单验证规则
    };
  },
  components: {
    quillEditor
  },
  methods: {
    /**
     * 重置
     */
    reset(){
      this.queryMap= {
        pageNum: 1,
        pageSize: 10,
        title: "",
        categoryId:""
      };
    },
    /**
     * 加载新闻列表
     */
    async getCheckList() {
      const { data: res } = await this.$http.get("check/findCheckList", {
        params: this.queryMap
      });
      if (res.code !== 200) return this.$message.error("获取招聘信息列表失败");
      this.total = res.data.total;
      this.checkList = res.data.list;
    },
    /**
     * 删除人物专访
     */
    async del(id) {
      var res = await this.$confirm(
        "此操作将永久删除该招聘信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
      if (res == "confirm") {
        const { data: res } = await this.$http.delete("check/delete/" + id);
        console.log(res);
        if (res.code == 200) {
          this.$notify.success({
            title:'操作成功',
            message:'招聘信息删除成功',
          });
          this.getCheckList();
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    /**
     * 打开添招聘信息框
     */
    openAdd() {
      this.addDialogVisible = true;
      this.addForm = {
        title: "",
        orgName:"",
        degree: "",
        area:"",
        content:""
      };
    },
    /**
     * 添加招聘信息
     */
    async addCheck() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          this.btnLoading = true;
          this.btnDisabled = true;
          const { data: res } = await this.$http.post("check/add", this.addForm);
          if (res.code == 200) {
            this.$notify.success({
              title:'操作成功',
              message:'招聘信息添加成功',
            });
            this.addForm = {
              title: "",
              orgName:"",
              degree: "",
              area:"",
              content:""
            };
            this.getCheckList();
          } else {
            return this.$message.error("招聘信息添加失败:" + res.msg);
          }
          this.addDialogVisible = false;
          this.btnLoading = false;
          this.btnDisabled = false;
        }
      });
    },
    /**
     * 更新招聘信息
     */
    async updateCheck() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          this.btnLoading = true;
          this.btnDisabled = true;
          const { data: res } = await this.$http.put(
            "check/update/" + this.editForm.id,
            this.editForm
          );
          if (res.code == 200) {
            this.$notify({
              title: "操作成功",
              message: "招聘信息信息已更新",
              type: "success"
            });
            this.editForm = {
              title: "",
              orgName:"",
              degree: "",
              area:"",
              content:""
            };
            this.getCheckList();
          } else {
            this.$message.error("招聘信息信息更新失败:" + res.msg);
          }
          this.editDialogVisible = false;
          this.btnLoading = false;
          this.btnDisabled = false;
        }
      });
    },
    /**
     * 搜索招聘信息
     */
    searchCheck() {
      this.queryMap.pageNum = 1;
      this.getCheckList();
    },
    /**
     * 修改招聘信息信息
     */
    async edit(id) {
      const { data: res } = await this.$http.get("check/edit/" + id);
      if (res.code == 200) {
        this.editForm = res.data;
        this.editDialogVisible = true;
      } else {
        return this.$message.error("招聘信息信息编辑失败:" + res.msg);
      }
    },
    /**
     *  改变页码
     */
    handleSizeChange(newSize) {
      this.queryMap.pageSize = newSize;
      this.getCheckList();
    },
    /**
     * 翻页
     */
    handleCurrentChange(current) {
      this.queryMap.pageNum = current;
      this.getCheckList();
    },

    /**
     * 关闭添加弹出框
     */
    closeDialog() {
      this.addForm = {
        title: "",
        orgName:"",
        degree: "",
        area:"",
        content:""
      };
    },
    cancelAdd(){
      this.addForm = {
        title: "",
        orgName:"",
        degree: "",
        area:"",
        content:""
      };
      this.addDialogVisible = false;
    },
    /**
     * 关闭编辑弹出框
     */
    editClose() {
      this.editForm = {
        title: "",
        orgName:"",
        degree: "",
        area:"",
        content:""
      };
    },
    cancelUpdate(){
      this.editForm = {
        title: "",
        orgName:"",
        degree: "",
        area:"",
        content:""
      };
      this.editDialogVisible = false;
    }
  },
  created() {
    this.getCheckList();
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  mounted() {
    this.tableHeight =
            window.innerHeight - this.$refs.myTable.$el.offsetTop - 84;
  }
};
</script>
<style>
  .box-card .disabled .el-upload--picture-card {
    display: none;
  }
  .box-card .el-upload--picture-card{
    width: 100px;
    height: 100px;
    line-height: 106px;
  }
  .box-card .el-upload-list--picture-card .el-upload-list__item{
    width:100px;
    height:100px;
    margin: 0 0px 0px 0;
  }
</style>
<style scoped>
  .quill-editor /deep/ .ql-editor { height: 200px; }
</style>

<template>
  <div id="users">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>服务范围</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 用户列表卡片区 -->
    <el-card class="box-card">
      <el-form :inline="true" ref="form" :model="queryMap" label-width="70px">
        <el-form-item label="标题">
          <el-input
            @keyup.enter.native="searchInterview"
            @clear="searchInterview"
            clearable
            v-model="queryMap.title"
            placeholder="请输入标题查询"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-left:50px;">
          <el-button  @click="reset" icon="el-icon-refresh">重置</el-button>
          <el-button type="primary" @click="searchInterview" icon="el-icon-search">查询</el-button>
          <el-button type="success" icon="el-icon-plus" @click="openAdd" v-hasPermission="'interview:add'">添加</el-button>
        </el-form-item>
      </el-form>

      <!-- 表格区域 -->
      <el-table
              v-loading="loading"
              :data="interviewList"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              stripe
              border
              style="width: 100%;" :height="tableHeight"
              ref="myTable">
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column prop="title" label="标题" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="source" label="摘要" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="newsUrl" label="图片" align="center" width="150px" padding="0px">
          <template slot-scope="scope">
            <el-popover placement="right"  trigger="hover">
              <img :src="origin +scope.row.url"  style="height: 200px;width: 200px"/>
              <img  slot="reference" :src="origin + scope.row.url" :alt="scope.row.url" style="height: 32px;width: 32px;cursor: pointer">
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180" sortable show-overflow-tooltip>
          <template slot-scope="scope" >
            <span>{{formatDateTime(scope.row.createTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button   v-hasPermission="'interview:edit'" size="mini" type="primary" icon="el-icon-edit-outline" @click="edit(scope.row.id)"></el-button>
            <el-button v-hasPermission="'interview:delete'" type="danger" size="mini" icon="el-icon-delete" @click="del(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="margin-top:10px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryMap.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryMap.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>

      <!-- 添加对话框 -->
      <el-dialog title="添加服务范围" @close="closeDialog" :visible.sync="addDialogVisible" width="60%;">
        <!-- 表单 -->
        <span>
          <el-form
            :model="addForm"
            :label-position="labelPosition"
            :rules="addFormRules"
            ref="addFormRef"
            label-width="80px"
          >
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="标题" prop="title">
                    <el-input v-model="addForm.title"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="摘要" prop="source">
                    <el-input type="textarea" v-model="addForm.source" :rows="4"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item label="背景图片" prop="url">
                    <el-upload
                        :action="upload"
                        :class="{ disabled: uploadDisabled }"
                        list-type="picture-card"
                        :limit="limitcount"
                        :on-remove="handleRemove"
                        accept="image/*"
                        :on-success="handleSuccess"
                        ref="upload"
                        :on-preview="handlePictureCardPreview"
                    >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" size="tiny">
                    <img width="100%" :src="dialogImageUrl" alt>
                  </el-dialog>
                  <div>尺寸:300*182像素,大小<=200KB</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="内容" prop="content">
                  <quill-editor
                          ref="myQuillEditor"
                          v-model="addForm.content"
                          :options="editorOption"
                          class="ql-editor"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAdd">取 消</el-button>
          <el-button type="primary" @click="addInterview" :loading="btnLoading" :disabled="btnDisabled">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改对话框 -->
      <el-dialog title="修改服务范围" :visible.sync="editDialogVisible" width="60%" @close="editClose">
        <span>
          <el-form
            :model="editForm"
            :label-position="labelPosition"
            :rules="addFormRules"
            ref="editFormRef"
            label-width="80px"
          >
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="标题" prop="title">
                    <el-input v-model="editForm.title"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="摘要" prop="source">
                    <el-input type="textarea" v-model="editForm.source" :rows="4"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item label="背景图片" prop="url">
                    <el-upload
                        :action="upload"
                        :class="{ disabled: uploadDisabledUpdate }"
                        list-type="picture-card"
                        :limit="limitcount"
                        :on-remove="handleRemoveUpdate"
                        accept="image/*"
                        :on-success="handleSuccessUpdate"
                        ref="upload"
                        :on-preview="handlePictureCardPreviewUpdate"
                        :file-list="imgFilesList"
                    >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisibleUpdate" size="tiny">
                    <img width="100%" :src="dialogImageUrlUpdate" alt>
                  </el-dialog>
                  <div>尺寸:300*182像素,大小<=200KB</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="内容" prop="content">
                  <quill-editor
                          ref="myQuillEditor"
                          :options="editorOption"
                          v-model="editForm.content"
                          class="ql-editor"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelUpdate">取 消</el-button>
          <el-button type="primary" @click="updateInterview" :loading="btnLoading" :disabled="btnDisabled">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import {quillEditor, Quill} from 'vue-quill-editor'
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/ImageResize', ImageResize)
let fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = ['10px', '12px', '14px', '16px', '20px', '24px', '36px', false] //false表示默认值
Quill.register(fontSizeStyle, true)
export default {
  data() {
    return {
      btnLoading: false,
      btnDisabled: false,
      departments: [],
      loading: true,
      total: 0,
      addDialogVisible: false, //添加对话框,
      editDialogVisible: false, //修改对话框
      labelPosition: "right", //lable对齐方式
      tableHeight:null,
      limitcount:1,
      uploadDisabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
      uploadDisabledUpdate:true,
      dialogImageUrlUpdate: "",
      dialogVisibleUpdate: false,
      imgFilesList: [],
      origin: this.getSysFileOrigin(),
      upload: this.getSysFileUpload(),
      uploadEditor: this.getSysEditorUpload(),
      editorOption: {
        placeholder:"请输入内容......",
        modules: {
          ImageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize']
          },
          ImageExtend: {
            loading: true,  // 可选参数 是否显示上传进度和提示语
            name: 'img',  // 图片参数名
            size: 1,  // 可选参数 图片大小，单位为M，1M = 1024kb
            action: this.getSysEditorUpload(),  // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return this.getSysFileOrigin() + res.files[0].fingerprint
            },
            headers: (xhr) => {},  // 可选参数 设置请求头部
            start: () => {},  // 可选参数 自定义开始上传触发事件
            end: () => {},  // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {},  // 可选参数 自定义网络错误触发的事件
            change: (xhr, formData) => {} // 可选参数 选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // container为工具栏，此次引入了全部工具栏，也可自行配置
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{'header': 1}, {'header': 2}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{'script': 'sub'}, {'script': 'super'}],
              [{'indent': '-1'}, {'indent': '+1'}],
              [{'direction': 'rtl'}],
              [{'size': fontSizeStyle.whitelist}],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              [{'color': []}, {'background': []}],
              [{'font': [] }],
              [{'align': []}],
              ['clean'],
              ['link', 'image', 'video']
            ],
            handlers: {
              'image': function () {  // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      },
      //查询对象
      queryMap: {
        pageNum: 1,
        pageSize: 10,
        title: "",
      },
      interviewList: [],
      addForm: {
        title: "",
        url:"",
        content:"",
        source:""
      }, //添加表单
      editForm: {}, //更新表单
      addFormRules: {
        title: [
          { required: true, message: "请输入标题", trigger: ['blur','change'] },
          { min: 3, max: 50, message: "长度在 3 到 50 个字符", trigger: ['blur','change'] }
        ],
        source: [
          { required: true, message: "请输入摘要", trigger: ['blur','change'] },
        ],
        url: [{ required: true, message: "图片不能为空", trigger: ['blur','change'] }],
      }, //添加表单验证规则
    };
  },
  components: {
    quillEditor
  },
  methods: {
    /**
     * 重置
     */
    reset(){
      this.queryMap= {
        pageNum: 1,
        pageSize: 10,
        title: "",
      };
    },
    /**
     * 删除图片 添加
     */
    handleRemove(file, fileList) {
      this.uploadDisabled = fileList.length >= this.limitcount;
      this.addForm.url = "";
    },
    /**
     * 图片预览 添加
     */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**
     * 成功添加图片 添加
     */
    handleSuccess(response, file, fileList) {
      debugger
      let f = response.files[0];
      this.addForm.url = f.fingerprint;
      this.uploadDisabled = fileList.length >= this.limitcount;
    },
    /**
     * 删除图片 修改
     */
    handleRemoveUpdate(file, fileList) {
      this.uploadDisabledUpdate = fileList.length >= this.limitcount;
      this.editForm.url = "";
    },
    /**
     * 图片预览 修改
     */
    handlePictureCardPreviewUpdate(file) {
      this.dialogImageUrlUpdate = file.url;
      this.dialogVisibleUpdate = true;
    },
    /**
     * 成功添加图片 修改
     */
    handleSuccessUpdate(response, file, fileList) {
      let f = response.files[0];
      this.editForm.url = f.fingerprint;
      this.uploadDisabledUpdate = fileList.length >= this.limitcount;
    },
    /**
     * 加载新闻列表
     */
    async getInterviewList() {
      const { data: res } = await this.$http.get("interview/findInterviewList", {
        params: this.queryMap
      });
      if (res.code !== 200) return this.$message.error("获取服务范围列表失败");
      this.total = res.data.total;
      this.interviewList = res.data.list;
    },
    /**
     * 删除服务范围
     */
    async del(id) {
      var res = await this.$confirm(
        "此操作将永久删除该服务范围, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
      if (res == "confirm") {
        const { data: res } = await this.$http.delete("interview/delete/" + id);
        console.log(res);
        if (res.code == 200) {
          this.$notify.success({
            title:'操作成功',
            message:'服务范围删除成功',
          });
          this.getInterviewList();
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    /**
     * 打开添加物资弹框
     */
    openAdd() {
      this.addDialogVisible = true;
      this.addForm = {
        title: "",
        source: "",
        url:"",
        content:""
      };
    },
    /**
     * 添加服务范围
     */
    async addInterview() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          this.btnLoading = true;
          this.btnDisabled = true;
          const { data: res } = await this.$http.post("interview/add", this.addForm);
          if (res.code == 200) {
            this.$notify.success({
              title:'操作成功',
              message:'服务范围添加成功',
            });
            this.addForm = {
              title: "",
              source: "",
              url:"",
              content:""
            };
            this.getInterviewList();
          } else {
            return this.$message.error("服务范围添加失败:" + res.msg);
          }
          this.$refs.upload.clearFiles();
          this.addDialogVisible = false;
          this.btnLoading = false;
          this.btnDisabled = false;
        }
      });
    },
    /**
     * 更新新闻
     */
    async updateInterview() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          this.btnLoading = true;
          this.btnDisabled = true;
          const { data: res } = await this.$http.put(
            "interview/update/" + this.editForm.id,
            this.editForm
          );
          if (res.code == 200) {
            this.$notify({
              title: "操作成功",
              message: "服务范围信息已更新",
              type: "success"
            });
            this.editForm = {
              title: "",
              source: "",
              url:"",
              content:""
            };
            this.getInterviewList();
          } else {
            this.$message.error("服务范围信息更新失败:" + res.msg);
          }
          this.editDialogVisible = false;
          this.$refs.upload.clearFiles();
          this.btnLoading = false;
          this.btnDisabled = false;
        }
      });
    },
    /**
     * 搜索服务范围
     */
    searchInterview() {
      this.queryMap.pageNum = 1;
      this.getInterviewList();
    },
    /**
     * 修改服务范围信息
     */
    async edit(id) {
      const { data: res } = await this.$http.get("interview/edit/" + id);
      if (res.code == 200) {
        this.editForm = res.data;
        var item = res.data;
        this.imgFilesList = [];
        this.imgFilesList.push({
          url: this.origin + item.url,
          name: item.name,
          id: item.id
        });
        this.editForm.source = item.source+"";
        this.editDialogVisible = true;
      } else {
        return this.$message.error("服务范围信息编辑失败:" + res.msg);
      }
    },
    /**
     *  改变页码
     */
    handleSizeChange(newSize) {
      this.queryMap.pageSize = newSize;
      this.getInterviewList();
    },
    /**
     * 翻页
     */
    handleCurrentChange(current) {
      this.queryMap.pageNum = current;
      this.getInterviewList();
    },

    /**
     * 关闭添加弹出框
     */
    closeDialog() {
      this.addForm = {
        title: "",
        source: "",
        url:"",
        content:""
      };
      this.uploadDisabled = false;
      this.$refs.upload.clearFiles();
    },
    cancelAdd(){
      this.addForm = {
        title: "",
        source: "",
        url:"",
        content:""
      };
      this.uploadDisabled = false;
      this.addDialogVisible = false;
      this.$refs.upload.clearFiles();
    },
    /**
     * 关闭编辑弹出框
     */
    editClose() {
      this.imgFilesList = [];
      this.editForm = {
        title: "",
        source: "",
        url:"",
        content:""
      };
      this.$refs.upload.clearFiles();
    },
    cancelUpdate(){
      this.imgFilesList = [];
      this.editForm = {
        title: "",
        source: "",
        url:"",
        content:""
      };
      this.editDialogVisible = false;
      this.$refs.upload.clearFiles();
    }
  },
  created() {
    this.getInterviewList();
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  mounted() {
    this.tableHeight =
            window.innerHeight - this.$refs.myTable.$el.offsetTop - 84;
  }
};
</script>
<style>
  .box-card .disabled .el-upload--picture-card {
    display: none;
  }
  .box-card .el-upload--picture-card{
    width: 100px;
    height: 100px;
    line-height: 106px;
  }
  .box-card .el-upload-list--picture-card .el-upload-list__item{
    width:100px;
    height:100px;
    margin: 0 0px 0px 0;
  }
</style>
<style scoped>
  .quill-editor /deep/ .ql-editor { height: 200px; }
</style>

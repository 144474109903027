<template>
  <div id="users">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>留言管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 用户列表卡片区 -->
    <el-card class="box-card">
      <el-form :inline="true" ref="form" :model="queryMap" label-width="70px">
        <el-form-item label="内容">
          <el-input
            @keyup.enter.native="searchGlory"
            @clear="searchGlory"
            clearable
            v-model="queryMap.content"
            placeholder="请输入内容查询"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-left:50px;">
          <el-button  @click="reset" icon="el-icon-refresh">重置</el-button>
          <el-button type="primary" @click="searchGlory" icon="el-icon-search">查询</el-button>
          <el-button type="success" icon="el-icon-plus" @click="openAdd" v-hasPermission="'glory:add'">添加</el-button>
        </el-form-item>
      </el-form>

      <!-- 表格区域 -->
      <el-table
              v-loading="loading"
              :data="gloryList"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              stripe
              border
              style="width: 100%;" :height="tableHeight"
              ref="myTable">
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column prop="name" label="姓名" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="telephone" label="联系电话" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="email" label="邮箱" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="content" label="内容" width="300" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180" sortable show-overflow-tooltip>
          <template slot-scope="scope" >
            <span>{{formatDateTime(scope.row.createTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button   v-hasPermission="'glory:edit'" size="mini" type="primary" icon="el-icon-edit-outline" @click="edit(scope.row.id)"></el-button>
            <el-button v-hasPermission="'glory:delete'" type="danger" size="mini" icon="el-icon-delete" @click="del(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="margin-top:10px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryMap.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryMap.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>

      <!-- 添加对话框 -->
      <el-dialog title="添加留言信息" @close="closeDialog" :visible.sync="addDialogVisible" width="60%;">
        <!-- 表单 -->
        <span>
          <el-form
            :model="addForm"
            :label-position="labelPosition"
            :rules="addFormRules"
            ref="addFormRef"
            label-width="80px"
          >
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model="addForm.name"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="邮箱" prop="email">
                    <el-input v-model="addForm.email"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="联系电话" prop="telephone">
                    <el-input v-model="addForm.telephone"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-form-item label="内容" prop="content">
                    <el-input type="textarea" rows="6" v-model="addForm.content"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAdd">取 消</el-button>
          <el-button type="primary" @click="addGlory" :loading="btnLoading" :disabled="btnDisabled">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改对话框 -->
      <el-dialog title="修改留言信息" :visible.sync="editDialogVisible" width="60%" @close="editClose">
        <span>
          <el-form
            :model="editForm"
            :label-position="labelPosition"
            :rules="addFormRules"
            ref="editFormRef"
            label-width="80px"
          >
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model="editForm.name"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="邮箱" prop="email">
                    <el-input v-model="editForm.email"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <el-form-item label="联系电话" prop="telephone">
                    <el-input v-model="editForm.telephone"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-form-item label="内容" prop="content">
                    <el-input type="textarea" rows="6" v-model="editForm.content"></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelUpdate">取 消</el-button>
          <el-button type="primary" @click="updateGlory" :loading="btnLoading" :disabled="btnDisabled">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      btnLoading: false,
      btnDisabled: false,
      loading: true,
      total: 0,
      addDialogVisible: false, //添加对话框,
      editDialogVisible: false, //修改对话框
      labelPosition: "right", //lable对齐方式
      tableHeight:null,
      limitcount:1,
      uploadDisabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
      uploadDisabledUpdate:true,
      dialogImageUrlUpdate: "",
      dialogVisibleUpdate: false,
      imgFilesList: [],
      origin: this.getSysFileOrigin(),
      upload: this.getSysFileUpload(),
      //查询对象
      queryMap: {
        pageNum: 1,
        pageSize: 10,
        title: "",
      },
      gloryList: [],
      addForm: {
        name: "",
        email:"",
        telephone:"",
        content:""
      }, //添加表单
      editForm: {}, //更新表单
      addFormRules: {
        name: [
          { required: true, message: "请输入姓名", trigger: ['blur','change'] },
          { min: 3, max: 10, message: "长度在 3 到 10 个字符", trigger: ['blur','change'] }
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: ['blur','change'] },
          { min: 3, max: 50, message: "长度在 3 到 50 个字符", trigger: ['blur','change'] }
        ],
        telephone: [
          { required: true, message: "请输入电话", trigger: ['blur','change'] },
          { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: ['blur','change'] }
        ],
        content: [
          { required: true, message: "请输入留言内容", trigger: ['blur','change'] },
        ],
      }, //添加表单验证规则
    };
  },
  methods: {
    /**
     * 重置
     */
    reset(){
      this.queryMap= {
        pageNum: 1,
        pageSize: 10,
        content: "",
      };
    },
    /**
     * 删除图片 添加
     */
    handleRemove(file, fileList) {
      this.uploadDisabled = fileList.length >= this.limitcount;
      this.addForm.url = "";
    },
    /**
     * 图片预览 添加
     */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**
     * 成功添加图片 添加
     */
    handleSuccess(response, file, fileList) {
      let f = response.files[0];
      this.addForm.url = f.fingerprint;
      this.uploadDisabled = fileList.length >= this.limitcount;
    },
    /**
     * 删除图片 修改
     */
    handleRemoveUpdate(file, fileList) {
      this.uploadDisabledUpdate = fileList.length >= this.limitcount;
      this.editForm.url = "";
    },
    /**
     * 图片预览 修改
     */
    handlePictureCardPreviewUpdate(file) {
      this.dialogImageUrlUpdate = file.url;
      this.dialogVisibleUpdate = true;
    },
    /**
     * 成功添加图片 修改
     */
    handleSuccessUpdate(response, file, fileList) {
      let f = response.files[0];
      this.editForm.url = f.fingerprint;
      this.uploadDisabledUpdate = fileList.length >= this.limitcount;
    },
    /**
     * 加载留言信息列表
     */
    async getGloryList() {
      const { data: res } = await this.$http.get("glory/findGloryList", {
        params: this.queryMap
      });
      if (res.code !== 200) return this.$message.error("获取留言信息列表失败");
      this.total = res.data.total;
      this.gloryList = res.data.list;
    },
    /**
     * 删除留言信息
     */
    async del(id) {
      var res = await this.$confirm(
        "此操作将永久删除该留言信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
      if (res == "confirm") {
        const { data: res } = await this.$http.delete("glory/delete/" + id);
        console.log(res);
        if (res.code == 200) {
          this.$notify.success({
            title:'操作成功',
            message:'留言信息删除成功',
          });
          this.getGloryList();
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    /**
     * 打开添留言信息弹框
     */
    openAdd() {
      this.addDialogVisible = true;
      this.addForm = {
        name: "",
        email:"",
        telephone:"",
        content:""
      };
    },
    /**
     * 添加留言信息
     */
    async addGlory() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          this.btnLoading = true;
          this.btnDisabled = true;
          const { data: res } = await this.$http.post("glory/add", this.addForm);
          if (res.code == 200) {
            this.$notify.success({
              title:'操作成功',
              message:'留言信息添加成功',
            });
            this.addForm = {
              name: "",
              email:"",
              telephone:"",
              content:""
            };
            this.getGloryList();
          } else {
            return this.$message.error("留言信息添加失败:" + res.msg);
          }
          this.addDialogVisible = false;
          this.btnLoading = false;
          this.btnDisabled = false;
        }
      });
    },
    /**
     * 更新留言信息
     */
    async updateGlory() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          this.btnLoading = true;
          this.btnDisabled = true;
          const { data: res } = await this.$http.put(
            "glory/update/" + this.editForm.id,
            this.editForm
          );
          if (res.code == 200) {
            this.$notify({
              title: "操作成功",
              message: "留言信息基本信息已更新",
              type: "success"
            });
            this.editForm = {
              name: "",
              email:"",
              telephone:"",
              content:""
            };
            this.getGloryList();
          } else {
            this.$message.error("留言信息信息更新失败:" + res.msg);
          }
          this.editDialogVisible = false;
          this.btnLoading = false;
          this.btnDisabled = false;
        }
      });
    },
    /**
     * 搜索留言信息
     */
    searchGlory() {
      this.queryMap.pageNum = 1;
      this.getGloryList();
    },
    /**
     * 修改留言信息信息
     */
    async edit(id) {
      const { data: res } = await this.$http.get("glory/edit/" + id);
      if (res.code == 200) {
        this.editForm = res.data;
        this.editDialogVisible = true;
      } else {
        return this.$message.error("留言信息编辑失败:" + res.msg);
      }
    },
    /**
     *  改变页码
     */
    handleSizeChange(newSize) {
      this.queryMap.pageSize = newSize;
      this.getGloryList();
    },
    /**
     * 翻页
     */
    handleCurrentChange(current) {
      this.queryMap.pageNum = current;
      this.getGloryList();
    },

    /**
     * 关闭添加弹出框
     */
    closeDialog() {
      this.addForm = {
        name: "",
        email:"",
        telephone:"",
        content:""
      };
    },
    cancelAdd(){
      this.addForm = {
        name: "",
        email:"",
        telephone:"",
        content:""
      };
      this.addDialogVisible = false;
    },
    /**
     * 关闭编辑弹出框
     */
    editClose() {
      this.imgFilesList = [];
      this.editForm = {
        name: "",
        email:"",
        telephone:"",
        content:""
      };
    },
    cancelUpdate(){
      this.imgFilesList = [];
      this.editForm = {
        name: "",
        email:"",
        telephone:"",
        content:""
      };
      this.editDialogVisible = false;
    }
  },
  created() {
    this.getGloryList();
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  mounted() {
    this.tableHeight =
            window.innerHeight - this.$refs.myTable.$el.offsetTop - 84;
  }
};
</script>
<style>
  .box-card .disabled .el-upload--picture-card {
    display: none;
  }
  .box-card .el-upload--picture-card{
    width: 100px;
    height: 100px;
    line-height: 106px;
  }
  .box-card .el-upload-list--picture-card .el-upload-list__item{
    width:100px;
    height:100px;
    margin: 0 0px 0px 0;
  }
</style>
<style scoped>
  .quill-editor /deep/ .ql-editor { height: 300px; }
</style>

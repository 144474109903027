<template>
  <el-container class="home-container">
<!--    导航-->
    <el-header>
      <div class="logo">
        <div class="logoImg">
          <a target="_blank" href="http://www.firegecko.cn/">
            <img src="../assets/logo.png" alt srcset/>
          </a>
        </div>
        <div class="topic">网站运营系统</div>
      </div>
      <div class="quit">

        <el-tooltip class="item" effect="dark" content="更新日志" placement="bottom">
          <a class="right-menu" href="javascript:;"><i class="el-icon-document-copy iconfont"></i></a>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="修改密码" placement="bottom">
          <a class="right-menu" href="javascript:;"><i class="el-icon-setting iconfont"></i></a>
        </el-tooltip>
        <span class="right-menu username">{{this.userInfo.nickname}}</span>
        <el-tooltip class="item" effect="dark" content="退出" placement="bottom">
          <a class="right-menu" href="javascript:;" @click="logout"><i class="el-icon-switch-button iconfont"></i></a>
        </el-tooltip>
      </div>
    </el-header>
    <!--主体-->
    <el-container style="height: 500px;">
      <!--菜单-->
      <el-aside :width="isOpen==true?'64px':'200px'">
        <div class="toggle-btn" @click="toggleMenu">|||</div>
        <el-menu
          class="el-menu-vertical-demo"
          :collapse="isOpen"
          :router="true"
          :default-active="activePath"
          background-color="#272c33"
          :collapse-transition="false"
          text-color="rgba(255,255,255,0.7)"
          unique-opened
        >
          <MenuTree  :menuList="this.menuList"></MenuTree>
        </el-menu>
      </el-aside>
      <!--右边主体-->
      <el-main v-loading="loading">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import MenuTree from "./MenuTree.vue"; //引进菜单模板

export default {
  data() {
    return {
      loading: true,
      activePath: "", //激活的路径
      isOpen: false,
      menuList: {},
      userInfo: {},
    };
  },
  components: {
    MenuTree
  },
  methods: {
    /**
     *
     * 退出登入
     */
    async logout() {
      var res = await this.$confirm("此操作将退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消退出登入"
        });
      });
      if (res == "confirm") {
        window.localStorage.clear();
        this.$router.push("/login");
      }
    },
    /**
      加载菜单数据
     */
    async getMenuList() {
      const { data: res } = await this.$http.get("user/findMenu");
      if (res.code !== 200)
        return this.$message.error("获取菜单失败:" + res.msg);
       this.menuList = res.data;
    },
    /**
      获取用户信息
     */
    async getUserInfo() {
      const { data: res } = await this.$http.get("user/info");
      if (res.code !== 200) {
        return this.$message.error("获取用户信息失败:" + res.msg);
      } else {
        this.userInfo = res.data;
        //保存用户
        this.$store.dispatch("updateUserInfo", res.data);
      }
    },
    /**
     * 菜单伸缩
     */
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },

    /**
     * 获取系统上传url
     */
    async getUploadUrl(){
      const { data: res } = await this.$http.get("file/uploadUrl");
      this.$store.dispatch("updateUploadUrl", res);
      localStorage.setItem("uploadUrl", res);
    }

  },
  mounted() {
    this.getUserInfo();
  },
  created() {
    this.getMenuList();
    this.activePath = window.sessionStorage.getItem("activePath");
    setTimeout(() => {
      this.loading = false;
    }, 500);
    this.getUploadUrl();
  }
};
</script>
<style>
.el-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 19px;
  padding-left: 0px;
}
.logo{
  height:70%;
}
.logo .logoImg{
  margin-left: 20px;
  display: inline-block;
}
.logo .logoImg img{
  width: 35px;
  height: 100%;
  margin-left: -10px;
}
.topic{
  display: inline-block;
  vertical-align: top;
  margin-top: 8px;
  font-size: 1.1rem;
  margin-left: 10px;
}
.el-aside {
  background-color:#272c33
}
.el-main {
  background-color: #eaedf1;
}
.home-container {
  width: 100%;
  height: 100% !important;
}
.toggle-btn {
  background-color: #2d3a4b !important;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.quit {
  position: relative;
}
.right-menu {
  width: 44px;
  height: 44px;
  display: inline-block;
  padding: 0 10px;
  color: #333;
  line-height: 44px;
}
.right-menu .iconfont {
  font-size: 22px;
}
.right-menu .iconfont:hover {
   background: rgba(0,0,0,.025);
}
.username {
  width: auto;
  padding: 0 16px 0 0;
  font-size: 0.8rem;
  float: right;
}
.username:hover {
   background: none;
}
</style>


